.form-container {
    background-color: #1a1a1d;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-heading {
    color: white;
}

input:disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
}
