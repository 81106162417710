.content .pagination-custom {
    margin: auto;
    display: table;
    color: #ffffff;
}

/* Pagination widget start */

btn-link[disabled]:focus, .navbar-inverse .btn-link[disabled]:hover, fieldset[disabled] .navbar-inverse .btn-link:focus, fieldset[disabled] .navbar-inverse .btn-link:hover {
    color: #444
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px
}

    .breadcrumb > li {
        display: inline-block
    }

        .breadcrumb > li + li:before {
            content: "/\A0";
            padding: 0 5px;
            color: #ccc
        }

    .breadcrumb > .active {
        color: #777
    }

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px
}

    .pagination > li {
        display: inline
    }

        .pagination > li > a, .pagination > li > span {
            position: relative;
            float: left;
            padding: 6px 12px;
            line-height: 1.42857143;
            text-decoration: none;
            color: #5D615D;
            background-color: #fff;
            border: 1px solid #ddd;
            margin-left: -1px
        }

        .pagination > li:first-child > a, .pagination > li:first-child > span {
            margin-left: 0;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px
        }

        .pagination > li:last-child > a, .pagination > li:last-child > span {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px
        }

        .pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
            z-index: 2;
            color: #23527c;
            background-color: #eee;
            border-color: #ddd
        }

    .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
        z-index: 3;
        color: #fff;
        background-color: #5D615D;
        border-color: #5D615D;
        cursor: default
    }

    .pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
        color: #777;
        background-color: #fff;
        border-color: #ddd;
        cursor: not-allowed
    }

.pagination-lg > li > a, .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333
}

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px
}

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px
}

.pagination-sm > li > a, .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5
}

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
}

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px
}

.pager {
    padding-left: 0;
    margin: 20px 0;
    list-style: none;
    text-align: center
}

    .pager li {
        display: inline
    }

        .pager li > a, .pager li > span {
            display: inline-block;
            padding: 5px 14px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 15px
        }

            .pager li > a:focus, .pager li > a:hover {
                text-decoration: none;
                background-color: #eee
            }

    .pager .next > a, .pager .next > span {
        float: right
    }

    .pager .previous > a, .pager .previous > span {
        float: left
    }

    .pager .disabled > a, .pager .disabled > a:focus, .pager .disabled > a:hover, .pager .disabled > span {
        color: #777;
        background-color: #fff;
        cursor: not-allowed
    }

/* Pagination widget end */
