

.checkbox {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    padding: 0.44rem 0.75rem;
    border-radius: 0.25rem;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 0px;
}
.unauthorised {
    background-color: white;
    margin: 40px;
    padding: 40px;
}