.hover-row:hover {
    background-color: #c1e1f6;
    cursor: pointer;
}

.chart-wrapper-2 {
    color: white;
    padding: 8px;
    background-color: #1a1a1d;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: #1A1A1D;
}

.info-desc {
    color: white;
}

.info-wrapper{
    border-radius: 10px;
    color: white;
    margin-top: 35px;
    background-color: #1a1a1d;
    padding: 10px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
}