.hover-row:hover {
    background-color: #c1e1f6;
    cursor: pointer;
}

.chart-wrapper-2 {
    color: white;
    padding: 8px;
    background-color: #1a1a1d;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: #1A1A1D;
}

.info-desc{
    color: white;
}

.leaderboard-date {
    padding: 8px;
    border-radius: 5px;
}

.fixed-leaderboard {
    position: sticky;
    top: 0;
    overflow-y: clip;
    padding: 8px;
    border-radius: 5px;
    margin-top: 5px;
}

.leaderboard-date2 {
    padding: 8px;
    background-color: #1A1A1D;
    margin-top: 15px;
    border-radius: 5px;
    justify-content: center;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
}