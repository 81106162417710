.chart-wrapper-crash {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;

}

.crash-chart-wrapper{
    background-color: white;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    border-radius: 15px;
}

.crash-map-wrapper{
}

.crash-chart-container{
    max-height: 100px;
}