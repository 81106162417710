.form-container {
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    color: white;
}

.contentvehicle3 h5 {
    margin-bottom: 20px;
    color: white;
}