.fixed-col {
    position: sticky;
    top: 0;
    height: 80vh;
    overflow-y: clip;
    padding: 8px;
    border-radius: 5px;
    margin-top: 5px;
}

@media (max-width: 1000px) {
    .fixed-col {
        position: sticky;
        top: 0;
        height: 100vh;
        overflow-y: clip;
        padding: 8px;
        border-radius: 5px;
        margin-top: 5px;
    }
}

.webcontainer {
    padding: 8px;
}

.gauge-chart-container {
    text-align: center;
    padding: 2px;
    margin-bottom: -20px;
    margin-top: -15px;
    
    
}

.gauge-chart-container h3 {
    font-size: 16px;
}

.gauge-chart-container .GaugeChart text {
    font-size: 12px;
}

.chart-wrapper {
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    transition: all 0.3s ease;
}

.chart-wrapper:hover {
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 6px 16px rgba(0, 128, 255, 0.31);
    transform: scale(1.03);
    
}

.tile {
    background-color: #1A1A1D;
    padding: 3px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.tile h5 {
    font-size: 16px;
    margin-bottom: 0;
    color: #d3d3d3;
}

.tile-content {
    font-size: 14px;
    color: white;
    margin-bottom: 0;
}

.chart-wrapper1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    
}

@media (max-width: 576px) {
    .chart-wrapper1 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        border-radius: 10px;
        box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 5px;
    }
}

.chart-col {
    padding: 10px;
}


.chart-title {
    font-weight: normal;
    font-size: 1.1em;
    text-align: center;
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.contentvehicle1 {
    padding: 2px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    border-radius: 5px;
    margin-bottom: 6px;
    text-align: center;
    color: #ffffff;
}

.contentvehicle h5 {
    text-align: center;
}

.gauge-text{
    color: white;
}



