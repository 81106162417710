.contentsearch {
    padding: 8px;
    border-radius: 5px;
    color: white;
}

.contentvehicle {
    border-color: rgba(0, 128, 255, 0.47);
    border-style: solid;
    border-width: 1px;
    padding: 2px;
    border-radius: 5px;
    align-items: center;
    margin-bottom: 6px;
    color: white;
}

.contentvehicle3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
    color: white;
    background-color: #1a1a1d;
    border-radius: 30px;
}

.fixed-col-journeylist{
    position: sticky;
    top: 0;
    overflow-y: clip;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    color: white;
}

.journeylist-buttons{
    padding: 5px;
}

.fixed-col2
{
    overflow-y: auto;
    padding: 0px;
}

.backbuttonlogoholder-journeylist
{
    margin-top: 10px;
}

.fixed-col {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: clip;
    padding: 8px;
    border-radius: 5px;
    margin-top: 5px;
}

.contentjourneylist {
    padding: 8px;
    right: 50%;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    color: white;
}

.table-dark.table-striped tbody tr:nth-of-type(odd){
    background-color: #1A1A1D;
}

.webcontainer {
    padding: 8px;
}

.contentcount {
    align-content: center;
    align-items: center;
}

.custom-marker {
    background-color: #003cff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.start-marker {
    background-color: #00ff00;
    width: 22px;
    height: 22px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.end-marker {
    background-color: #ff0000;
    width: 22px;
    height: 22px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.table-striped tbody tr.active {
    background-color: rgba(0, 156, 204, 0.35) !important;
}



.event-marker {
    width: 22px;
    height: 22px;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 50%;
    text-align: center;
}

.event-marker-yellow {
    background-color: #e6ff00;
}

.event-marker-orange {
    background-color: #ffcc00;
}

.event-marker-darkorange {
    background-color: #ff5900;
}

.event-marker-red {
    background-color: #ff0000;
}

.marker {
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
}

.debug-marker{
    background-color: #002aff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .fixed-col-journeylist{
        position: fixed;
        width: 95%;
        margin-top: 25%;
        background-color: #1A1A1D;
        height: 40%;
        top: 0;
        overflow-y: clip;
        padding: 8px;
        border-radius: 5px;
        box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
        color: white;
    
    }

    .contentjourneylist {
        padding-top: 40px;
        margin-top: 100%;
        border-radius: 5px;
        box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
        color: white;
    }
}