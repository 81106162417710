.fixed-col {
    position: sticky;
    top: 0;
    height: 70vh;
    overflow-y: clip;
    padding: 8px;
    border-radius: 5px;
    margin-top: 5px;
}

.webcontainer {
    padding: 8px;
}

.gauge-chart-container {
    text-align: center;
    padding: 2px;
    margin-bottom: -20px;
    margin-top: -15px;
}

.gauge-chart-container h3 {
    font-size: 16px;
}

.gauge-chart-container .GaugeChart text {
    font-size: 12px;
}

.chart-wrapper-1 {
    padding: 10px;

}

.chart-wrapper-company-overview {
    margin-top: 0px;
    box-shadow: 0 0px 4px rgba(234, 229, 229, 0.08);
    transition: all 0.3s ease;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;

}

.chart-wrapper-company-overview:hover {
    box-shadow: 0 6px 16px rgba(0, 128, 255, 0.31);
    transform: scale(1.05);
}

.chart-wrapper3 {
    box-shadow: 0 2px 4px rgba(0, 128, 255, 0.3);
}

.tile1 {
    background-color: rgba(246, 246, 244, 0.81);
    padding: 8px;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.tile1 h5 {
    font-size: 16px;
    margin-bottom: 10px;
}

.tile-content1 {
    font-size: 14px;
    margin-bottom: 2px;
}

.chart-wrapper2 {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 128, 255, 0.3);
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 2px solid #ddd;
    padding: 5px;
}

.chart-col {
    padding: 10px;
}

.chart-title {
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    color: white;
}

.table {
    border-color: #1A1A1D;
    border-collapse: collapse;
    border-spacing: 0;
}

.table th {
    background-color: #1A1A1D;
    color: white;
    font-style: normal;
    text-align: center;
}

.table tr {
    text-align: center;
}

.table-striped tbody tr:nth-child(even) {
    background-color: #222225;
    color: white;
}

.table-striped tbody tr:nth-child(odd) {
    background-color: #26262a;
    color: white
}

.crash-table-border {
    background-color: #1a1a1d;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    padding: 10px;
}

.chart-wrapper-company {
    background-color: #1a1a1d;
    color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0px 4px rgba(234, 229, 229, 0.08);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 130px;
    text-align: center;
}

.chart-wrapper-company h5,
.chart-wrapper-company p {
    margin: 8px 0;
}

.chart-wrapper-company p {
    font-size: 0.85em;
}

.chart-wrapper-company h5 {
    font-size: 1.1em;
    font-weight: 600;
}

/* Hover effect */
.chart-wrapper-company:hover {
    box-shadow: 0 6px 16px rgba(0, 128, 255, 0.2);
    transform: translateY(-5px);
}

@media only screen and (max-width: 768px) {
    .chart-wrapper-company {
        margin: 10px 0;
    }
}



