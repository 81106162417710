.contentsearch {
    padding: 8px;
    background-color: #1A1A1D;
    margin-top: 15px;
    border-radius: 5px;
    justify-content: center;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    
}

.left-align {
    text-align: left;
}

.myvehicles-button {
    padding: 8px;
    background-color: #1A1A1D;
    margin-top: 16px;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    justify-content: center;
}

@media (max-width: 768px) {
    .lottie-animation {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .table-responsive {
        overflow-x: auto;
        white-space: nowrap;
    }

    .custom-button {
        display: block;
        width: 100%;
        height: 100%;
    }
}


.view-button {
    padding: 3px 3px;
    background-color: rgba(44, 127, 246, 0.85);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}

.cell-padding {
    padding: 0 0px;
}

.view-button:hover {
    background-color: #0026b1;
}

.table {
    width: 100%;
    font-size: 15px;
    border-color: #1A1A1D;
    border-collapse: collapse;
    border-spacing: 0;
}

.table th,
.table td {
    padding: 6px;
    vertical-align: middle;
}

.table {
    border-color: #1A1A1D;
    border-collapse: collapse;
    border-spacing: 0;
}

.table th {
    background-color: #1A1A1D;
    color: white;
    font-style: normal;
}

.table-striped tbody tr:nth-child(even) {
    background-color: #222225;
    color: white;
}

.table-striped tbody tr:nth-child(odd) {
    background-color: #26262a;
    color: white
}

.table-striped tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.journey-inspection {
    display: flex;
    flex-direction: column;
}

.journey-inspection-map {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.journey-inspection-details {
}

.map-container {
    width: 100%;
    height: 400px;
}

button {
}

.fixed-col1 {
    top: 0;
    padding: 8px;
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 20px;
}

.content-vehicle-list {
    color:white;
    padding: 8px;
    background-color: #1a1a1d;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: #1A1A1D;
}
