.col-sm-12 {
    padding-left: 0;
    padding-right: 0;
}

body .webcontainer1 {
    margin-left:auto;
    margin-right:10px;
    width:84%;
    margin-top:1px;
    transition: width 0.3s ease;
}

body .webcontainer1.full-width {
    width: 96%;
}

/* Mobile styles */
@media (max-width: 768px) {
    body .webcontainer1 {
        margin-left: auto;
        margin-right: 10px;
        width: 100%;
        margin-top: 1px;
        transition: width 0.3s ease;
    }

    body .webcontainer1.full-width {
        width: 100%;
    }
}