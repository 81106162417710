.map-full {
    height: 85vh
}

.marker p {
    position: absolute;
    top: -15px;
    background-color: #1A1A1D;
    color: white;
    left: 50%;
    transform: translate(-50%, -100%);
    margin: 0;
}

.tileCharts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
}

.tile-col {
    padding-top: 20px;
    padding-bottom: 20px;
}

.dark-popup {
    background-color: #ffffff;
    color: #000000;
}

.full-width-dropdown button {
    width: 100%;
}

.dark-popup a {
    color: white;
}

.dark-popup button {
    background-color: #0080ff;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    transition-duration: 0.4s;
}

.dark-popup button:hover {
    background-color: #888;
    color: white;
}

.chart-wrapper-3 {
    color: white;
    padding: 8px;
    margin-top: 20px;
    background-color: #1a1a1d;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: #1A1A1D;
}

.full-button {
    display: block;
    width: 100%;
    height: 100%;
}

.dropdownOptions {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #1A1A1D;
    color: #1A1A1D;

}

.fixed-col-vehicle-view {
    width: 54%;
    height: 90%;
    position: fixed;
    top: 8%;
    overflow-y: clip;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
    color: white;
}

.vehicle-list-table {
    position: relative;
    margin-top: 1%;
}

@media (max-width: 768px) {
    .fixed-col-vehicle-view {
        width: 90%;
        height: 40%;
        position: fixed;
        top: 8%;
        background-color: #1A1A1D;
        overflow-y: clip;
        padding: 8px;
        border-radius: 5px;
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.50);
        color: white;
    }

    .vehicle-list-table {
        margin-top: 100%;
    }

    .tileCharts {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        border-radius: 10px;
        box-shadow: 0 0 8px rgba(223, 231, 232, 0.29);
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 5px;
    }
}