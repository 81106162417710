.dropdown-item {
    padding: 15px;
    display: block;
}

.bg-light {
    background-color: #1A1A1D !important;
}

.icon-car {
    width: 20px;
    height: auto;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.1rem;
}

.dropdown-item .icon {
    margin-right: 10px;
}

.navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
    margin-right: 20px;
    padding-left: 10px;
}

    .navbar-dark .navbar-nav .nav-link:hover {
        color: rgba(41, 170, 255);
        margin-right: 20px;
    }

    .navbar-dark .navbar-nav .nav-link:focus {
        color: rgba(41, 170, 255, 0.35);
        margin-right: 20px;
    }

    .navbar-dark .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active {
        color: rgba(41, 170, 255, 0.35);
    }

    .navbar-dark .navbar-nav .nav-link .icon {
        margin-right: 5px;
    }

.navbar-link {
    margin: 10px;
}

.nav-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.nav-item-content .icon {
    margin-bottom: 5px;
}

.navbar-toggler-icon.custom-icon {
    color: red;
}


a {
    color: #000000;
}

    a:hover {
        color: #ffffff;
        text-decoration: underline;
    }


.sidebar {
    position: fixed;
    height: 100%;
    width: 15%;
    background-color: #222226;
    padding: 0px;
    z-index: 1000;
    pointer-events: auto;
}

.brand {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 18px;
}

.brand img {
    width: 90%;
    height: 100%;
    object-fit: contain;
}

.nav-link-custom {
    color: #ffffff;
    padding: 10px 0;
    pointer-events: auto;
    font-size: medium;

}
.nav-link-custom-login {
    color: #ffffff;
    padding: 10px 0;
    pointer-events: auto;
    font-size: small;

}

.nav-link-content{
    margin-left: 10px;
}

.nav-link-custom:hover {
    background-color: #094f7e;

}

.icon {
    margin-right: 10px;
}

/* Mobile styles */
@media (max-width: 900px) {
    .sidebar {
        width: 100%;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
    }

    .hamburger-btn {
        display: block;
        position: fixed;
        top: 10px;
        left: 10px;
        z-index: 1001;
    }

    .sidebar.visible {
        transform: translateY(0);
    }

    .nav-link-custom, .nav-link-custom-login {
        padding: 2px 5px;
    }

    .icon {
        margin-right: 15px;
    }
    
    .brand {
        margin-bottom: 10px;
        margin-left: 65px;
        margin-top: 15px;
    }
    .brand img {
        width: 50%;
        height: 40%;
        object-fit: contain;
    }
}

@media (max-width: 900px) {
    .sidebar {
        width: 100%;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
    }

    .hamburger-btn {
        display: block;
        position: fixed;
        top: 10px;
        left: 10px;
        z-index: 1001;
    }

    .sidebar.visible {
        transform: translateY(0);
    }

    .nav-link-custom, .nav-link-custom-login {
        padding: 2px 5px;
    }

    .icon {
        margin-right: 15px;
    }

    .brand {
        margin-bottom: 10px;
        margin-left: 65px;
        margin-top: 15px;
    }
    .brand img {
        width: 50%;
        height: 40%;
        object-fit: contain;
    }
}

/* Desktop styles */
@media (min-width: 900px) {
    .hamburger-btn {
        display: none;
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
}

/* Default behavior for larger screens */
@media (min-width: 768px) {
    .sidebar {
        justify-content: space-between;
    }
}

/* Behavior for mobile screens */
@media (max-width: 767px) {
    .sidebar {
        justify-content: normal;
    }
}
