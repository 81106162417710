body {
    background-color: #111113;
}

    body .webcontainer{
        margin-left:auto;
        margin-right:10px;
        width:100%;
        margin-top:1px;
    }

        body .webcontainer .center{
            text-align: center;
        }

        body .webcontainer .right {
            text-align: right;
        }

        body .webcontainer .bold {
            font-weight:bold;
        }

        body .webcontainer .capital {
            text-transform: capitalize;
        }

        body .webcontainer .content {
            padding: 10px;
            margin-top: 10px;
            min-height: 10px;
        }

            body .webcontainer .content h2 {
                font-size: 14pt;
                color: #ffffff;
            }

            body .webcontainer .content h3 {
                font-size: 15pt;
                color: #ffffff;
            }

        body .webcontainer div.content.search > button {
            margin-right: 5px
        }

@media (max-width: 900px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        background-color: #000000;
    }
}

